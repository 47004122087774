const steps = {
    initial: 'initial',
    login: 'login',
    missing1: 'missing1',
    missing2: 'missing2',
    register1: 'register1',
    register2: 'register2',
    team: 'team',
    error: 'error'
};

export default steps;