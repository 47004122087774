/* global grecaptcha, gapi */
import $ from 'jquery';
import { format } from 'date-fns';
import { API_URL, CAPTCHA_KEY } from './constants';

const $date = $('#form-section-date');
if ($date && $date.length) {
    $date.val(format(new Date($date.val()), 'h:mm a, do MMMM yyyy'));

    window.grecaptcha.ready(function () {
        recaptchaId = grecaptcha.render(
            'captcha-container-3',
            {
                sitekey: CAPTCHA_KEY,
                callback: captchaCallback,
                size: 'invisible',
                badge: 'bottomright'
            }
        );
    });

}

let recaptchaId;

$("#webinar-page-form").submit(function (e) {
    e.preventDefault();
    grecaptcha.execute(recaptchaId);
});

async function captchaCallback(token) {
    grecaptcha.reset(recaptchaId);
    const self = $("#webinar-page-form");
    const $submitField = self.find('#contact-submit-webinar');
    $submitField.prop('disabled', true);
    self.find('#contact-submit-webinar').prop('disabled', true);
    const data = self.closest('form').serializeArray();
    data.push({name: 'token', value: token});
    const $errorField = self.find('.error-message');
    $errorField.text('');

    $.ajax({
        data: data,
        method: 'POST',
        url: API_URL + 'webinar.php'
    }).done(function () {
        self.find('input:not(#form-section-date, #hidden-form-webinar-id), textarea').val('');
        self.find('select').val('0 - 50');
        self.find('#contact-submit-webinar').prop('disabled', false);
        window.location.href = '/webinar/success';
    }).fail(function (e) {
        const error = JSON.parse(e.responseText).error || 'Something went wrong. Please try again later.';
        $errorField.text(error);
        $submitField.prop('disabled', false);
    });
}


$('#select-session-button').on('click', function () {
    const $offset = $('.webinar .top-section').offset();
    if ($offset) {
        $('html,body').animate({
            scrollTop: $offset.top
        }, 500);
    }
});


