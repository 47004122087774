import {APP_URL, CLIENT_ID, CLIENT_SECRET, COOKIE_DOMAIN, HEELIX_URL} from '../constants';
import steps from './steps';
import register2 from './views/register2';
import loginScreen from './views/loginScreen';
import forgotPassword from './views/forgotPassword';
import redirectToHeelix from './redirectToHeelix';

export default async function handleLogin(email, password) {
    let data = new FormData();
    data.append('grant_type', 'password');
    data.append('client_id', CLIENT_ID);
    data.append('client_secret', CLIENT_SECRET);
    data.append('username', email);
    data.append('password', password);
    data.append('scope', '');

    const response = await fetch(`${APP_URL}/oauth/token`, {method: 'post', body: data});
    const json = await response.json();
    if (!(response.status === 200 && response.ok === true)) {
        let title;
        if (json.message === "The user credentials were incorrect.") {
            title = `<span class="error-color">Oops, that password doesn't match</span>`;
        } else {
            title = `<span class="error-color">${json.message}</span>`;
        }

        return {
            step: steps.login,
            loading: '',
            title: title,
            buttonText: 'Login',
            bottomText: forgotPassword,
            content: loginScreen(email, true),
            email
        };
    } else {
        const cookieName = 'heelix_auth_token';
        const cookieValue = json.access_token;
        let myDate = new Date(new Date().getTime() + 3600 * 1000);
        document.cookie = cookieName + "=" + cookieValue + ";" + COOKIE_DOMAIN + "path=/;expires=" + myDate;
        redirectToHeelix();

        return {loading: 'Verifying credentials'};
    }
}
