import {APP_URL} from '../constants';
import handleGoogleLogin from './handleGoogleLogin';

export default function handleGoogleRegister(email, token, teamName, agreedToTerms, onSuccess, onFailure) {

    let data = new FormData();
    data.append('username', email);
    data.append('token', token);
    data.append('name', teamName);
    data.append('terms', agreedToTerms);

    fetch(APP_URL + '/register_google', {method: 'post', body: data})
        .then((response) => {
            if(response.status === 200 && response.ok === true) {
                response.json().then(() => {
                    handleGoogleLogin(email, token)
                        .then(() => {
                            onSuccess();
                        });
                }).catch((error) => {
                    onFailure(error);
                });
            } else {
                if (response.status === 422) {
                    response.json()
                        .then((json) => {
                            const error = Object.values(json)[0];
                            onFailure(error);
                        });
                } else {
                    response.json()
                        .then((json) => {
                            // onFailure(json);
                        });
                }

            }
        }).catch((error) => {
        onFailure(error);
    });
}
