const missingInvite2 = selectStep => `
        <div class="verify-email invite-sent">
            <div>
                We've just resent an invite to the email linked with your account.
            </div>
            <div>
                Please follow the instructions to get started.
            </div>
            <div class="verify-didnt-receive">
                Didn't receive an email? <a id="resend-invite-step">Click here</a>.
            </div>
        </div>
`;

export default missingInvite2;