/* global grecaptcha, gapi */
import $ from 'jquery';
import button from './views/button';
import body from './views/body';
import loadingOverlay from './views/loadingOverlay';
import handleVerifyEmail from './handleVerifyEmail';
import steps from './steps';
import handleInviteResend from './handleInviteResend';
import missingInvite1 from './views/missingInvite1';
import handleRegister from './handleRegister';
import register1 from './views/register1';
import handleLogin from './handleLogin';
import {API_URL, APP_URL, CAPTCHA_KEY, GOOGLE_KEY} from '../constants';
import handleGoogleLogin from './handleGoogleLogin';
import team from './views/team';
import handleGoogleRegister from './handleGoogleRegister';

let recaptchaId;
const initialState = {
    step: steps.initial,
    title: 'Welcome to Heelix!',
    content: body(),
    // content: loginScreen('qwe@gmail.com'),
    buttonText: 'Go',
    loading: '',
    bottomText: '',
    email: '',
    error: ''
};

let auth2;
const loadGapi = () => gapi.load('auth2', function () {
    auth2 = gapi.auth2.init({
        client_id: GOOGLE_KEY,
        cookiepolicy: 'single_host_origin',
        prompt: 'select_account',
    });
    const btn = document.getElementById('google-signin-button');
    btn && attachSignin(btn);
    $('#google-signin-button').click(() => {
        setState({loading: 'Verifying credentials'});
    });
});

let state = initialState;
let signInFormEmail = '';
let captchaRendered = false;
let isContactForm = false;

async function captchaCallback(token) {
    grecaptcha.reset(recaptchaId);
    if (isContactForm) {
        return submitContactForm(token);
    }

    let newState;
    switch (state.step) {
        case steps.initial:
            newState = await handleVerifyEmail(state.email, token);
            break;
        case steps.register1:
            newState = await handleRegister(state.email, token);
            break;
    }
    setState(newState);
}

const render = () => {
    $('#sign-in-modal-title').html(state.title);

    let buttonSelector;

    if (state.buttonText) {
        $('#sign-in-modal-button-container').html(button);
        buttonSelector = $('#sign-in-modal-button');
        buttonSelector.text(state.buttonText);
    } else {
        $('#sign-in-modal-button-container').empty();
    }

    if (state.error) {
        $('#sign-in-modal-error-container').text(state.error);
    } else {
        $('#sign-in-modal-error-container').empty();
    }

    const bodySelector = $('#sign-in-modal-body');

    bodySelector.html(state.content);

    if (state.bottomText) {
        $('#sign-in-modal-bottom-text').html(state.bottomText);
    } else {
        $('#sign-in-modal-bottom-text').empty();
    }

    if (state.loading) {
        bodySelector.prepend(loadingOverlay(state.loading, '40%'));
        buttonSelector.attr('disabled', true);
    }

    $('#resend-invite-step').on('click', () => {
        setState({
            step: steps.missing1,
            loading: '',
            title: 'Missing your invitation?',
            buttonText: 'Request invite',
            bottomText: '',
            content: missingInvite1(state.email),
        });
    });

    $('#resend-verify-step').on('click', () => {
        setState({
            step: steps.register1,
            loading: '',
            title: 'Looks like you\'re new!',
            buttonText: 'Continue with account creation',
            bottomText: '',
            content: register1(state.email),
        });
    });
    loadGapi();
    setupAppleSignin();
};

const setState = (newState) => {
    state = {...state, ...newState};
    render();
};

$('.chatModal').on('hidden.bs.modal', () => {
    isContactForm = false;
    $('#captcha-container').css('visibility', 'hidden');
    setState(initialState);
});
$('.chatModal').on('shown.bs.modal', () => {
    $('#captcha-container').css('visibility', 'visible');
    if (!captchaRendered) {
        recaptchaId = grecaptcha.render(
            'captcha-container',
            {
                sitekey: CAPTCHA_KEY,
                callback: captchaCallback,
                size: 'invisible',
                badge: 'bottomright'
            }
        );
        captchaRendered = true;
        if (signInFormEmail) {
            setState({loading: 'Verifying email', email: signInFormEmail});
            grecaptcha.execute(recaptchaId);
        }
    }
});

function submitContactForm(token) {
    const self = $("#contact-form");
    const $submitField = self.find('#contact-submit');
    $submitField.prop('disabled', true);
    const $data = self.closest('form').serializeArray();
    $data.push({name: 'token', value: token});
    const $errorField = self.find('.error-message');
    $errorField.text('');

    $.ajax({
        data: $data,
        method: 'POST',
        url: API_URL + 'contact.php'
    }).done(function () {
        self.find('input, textarea').val('');
        self.find('select').val('0 - 50');
        self.find('#contact-submit').prop('disabled', false);
        $('#chatModal').modal('toggle');
        window.location.href = '/demo/success';
    }).fail(function (e) {
        const error = JSON.parse(e.responseText).error || 'Something went wrong. Please try again later.';
        $errorField.text(error);
        $submitField.prop('disabled', false);
    });
}

$("#contact-form").submit(function (e) {
    e.preventDefault();
    isContactForm = true;
    grecaptcha.execute(recaptchaId);
});

render();

for (let i = 1; i <= 6; i++) {
    $(`#sign-in-form${i}`).submit(async e => {
        e.preventDefault();
        $('#chatModal').modal('show');
        $('#exampleInputEmail1').val(e.target.email.value);
    });
}

const addDemoToEmail = (email) => {
    let array = email.split('@');
    array[0] = array[0] + '+demo';
    return array.join('@');
};

$('#sign-in-modal').submit(async e => {
    e.preventDefault();
    const team = e.target.team_name;
    const terms = e.target.terms_and_privacy_accepted;
    let email = e.target.email ? e.target.email.value : state.email;
    const pwd = e.target.password ? e.target.password.value : '';
    let newState;
    switch (state.step) {
        case steps.initial:
            setState({loading: 'Verifying email', email});
            grecaptcha.execute(recaptchaId);
            break;
        case steps.login:
            setState({loading: 'Verifying credentials'});
            if (state.user === 'demo') {
                email = addDemoToEmail(email);
            }
            newState = await handleLogin(email, pwd);
            setState(newState);
            break;
        case steps.missing1:
            setState({loading: 'Processing...'});
            newState = await handleInviteResend(email);
            setState(newState);
            break;
        case steps.register1:
            setState({loading: 'Processing...'});
            grecaptcha.execute(recaptchaId);
            break;
        case steps.team:
            setState({loading: 'Processing...', error: ''});
            newState = await handleGoogleRegister(
                state.googleUser.getBasicProfile().getEmail(),
                state.googleUser.getAuthResponse(true).access_token,
                team.value,
                terms.value,
                null,
                error => setState({
                    loading: '',
                    error,
                })
            );
            // setState(newState);
            break;
    }

});

function attachSignin(element) {
    auth2.attachClickHandler(element, {},
        onSuccess, function (error) {
            console.log(JSON.stringify(error, undefined, 2));
        });
}

export function setupAppleSignin() {
    window.AppleID.auth.init({
        clientId: 'com.winning.heelix',
        scope: 'name email',
        // redirectURI: `http://dev.coderspeak.com:9001/apple_redirect`, //for local debugging
        redirectURI: `${APP_URL}/apple_redirect`,
    });
}

async function onSuccess(user) {
    const token = user.getAuthResponse(true).access_token;
    const response = await fetch(`${APP_URL}/lookup_google?token=${token}`);
    const json = await response.json();
    if (json.user === 'exists') {
        setState({loading: 'Processing...'});
        const newState = await handleGoogleLogin(user.getBasicProfile().getEmail(), token);
        setState(newState);
    } else if (json.user === "doesn't exist") {
        setState({
            step: steps.team,
            loading: '',
            title: 'Finally, set up a team name',
            buttonText: 'Let\'s go',
            bottomText: '',
            content: team(),
            googleUser: user
        });
    } else if (json.user === 'not company email') {
        setError(json.message);
    }
}

export function setError(error) {
    setState({
        step: steps.error,
        loading: '',
        title: `<span class="error-color">Someting went wrong</span>`,
        buttonText: '',
        bottomText: '',
        content: `<div class="error-page-body">${error}</div>`,
    });
}
