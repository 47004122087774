const loadingOverlay = (message, height = `40%`) => `
        <div class=loading-overlay style="height: ${height}">
            <div class="overlay-container mx-auto">
                <div class="spinner"/>
                <div class=overlay-description>
                    ${message}
                </div>
            </div>
        </div>
`;

export default loadingOverlay;
