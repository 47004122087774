import $ from 'jquery';
import throttle from 'lodash.throttle';

$(function () {
    const a = function () {
        let b = $(window).scrollTop() - 50;
        let d = $("#nav-anchor").offset().top;
        let c = $("#navbar");
        let navbarOther = $(".navbar-light-other");
        let video = $("#video-nav-background");
        let hi = $(".header-illustration");
        let dl = $(".desktop-logo");
        let hamburger = $("#hamburger");
        if (b >= d) {
            c.css({ position: "fixed", top: "0px", background: "white"});
            c.addClass('scrolled-navbar');
            hi.addClass('scrolled-header-illustration');
            dl.addClass('scrolled-desktop-logo');
            video.removeClass("d-none");
        } else {
            if(!c.hasClass('navbar-other')) c.css({ position: "relative", top: "0px" , background: "none"});
            navbarOther.css({ position: "relative", top: "0px", background: "white"});
            c.removeClass('scrolled-navbar');
            hi.removeClass('scrolled-header-illustration');
            dl.removeClass('scrolled-desktop-logo');
            if (!(hamburger.hasClass('is-active')) && b <= d) {
                video.addClass("d-none");
            }
        }
    };
    $(window).scroll(throttle(a, 100));
    a();
});

$(function () {
    let hamburger = $("#hamburger");
    let video = $("#video-nav-background");
    let videoOther = $("#video-nav-background-other");
    let c = $("#navbar");
    hamburger.click(() => {
        let b = $(window).scrollTop();
        let d = $("#nav-anchor").offset().top;
        toggleDesktopNavbar();
        // $('#sign-in-button').collapse('toggle');
        if (hamburger.hasClass('is-active')){  //before collapse
            hamburger.removeClass('is-active');
            if (b <= d) video.addClass("d-none");
            if((!c.hasClass('navbar-other')) && b <= d) c.css({ position: "relative", top: "0px", background: "none"});
            video.css({top: "500%"});
            videoOther.css({top: "500%", background: "#1C74F9"});
        } else { //after click hamburger
            hamburger.addClass('is-active');
            c.css({ position: "fixed", top: "0px", background: "white"});
            video.css({top: "160%"});
            videoOther.css({top: "160%"});
            video.removeClass("d-none");
        }
    });
});



async function toggleDesktopNavbar() {
    // let selector = $('.desktop-nav, .desktop-logo, .sign-buttons');
    let selector = $('.desktop-nav');
    if (!selector.hasClass('shown')) {
        fadeComponent($('.sign-buttons'));
        fadeComponent($('.desktop-logo'));
        await sleep(150);
        fadeComponent(selector);
    } else {

        fadeComponent(selector);
        await sleep(150);
        // $('.desktop-logo').collapse('toggle');
        fadeComponent($('.desktop-logo'));
        // $('.sign-buttons').collapse('toggle');
        fadeComponent($('.sign-buttons'));
    }
}

export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

async function fadeComponent(selector) {

    let fade = 'fadeOut';
    if (!selector.hasClass('shown')) {
        fade = 'fadeIn';
        selector.addClass('shown');
        await sleep(50);
    }
    selector.addClass(fade);
    await sleep(300);
    if (fade === 'fadeOut') {
        selector.removeClass(fade);
        selector.removeClass('fadeIn');
        selector.toggleClass('shown');
    } else {
    }
}


