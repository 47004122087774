import * as contentful from 'contentful';
import {documentToHtmlString} from '@contentful/rich-text-html-renderer';
import {BLOCKS} from '@contentful/rich-text-types';
import $ from 'jquery';
import blogTile from './views/blogTile';
import customRenderer from './customRenderer';
import blogPagination from './views/blogPagination';
import blogHeader from './views/blogHeader';
import calculateReadTime from './calculateReadTime';
import blogTags from './views/blogTags';
import {API_URL} from '../constants';
import {sleep} from '../navbar';

export function handleEllipsis(text, characterCount) {
    return text.slice(0, text.slice(0, characterCount).lastIndexOf(' ')) + '...';
}


async function handleBlog() {
    const path = window.location.pathname;
    if (path.indexOf('blog') === -1) return;

    const client = contentful.createClient({
        space: '0j1u7kevpjpm',
        accessToken: 'CNVBKRF3ljYsE4ukucvaHWbykJIRvy_RYizk2wervsU'
    });

    let blogPosts = {};
    let featuredPost = {};
    let singlePost = {};
    let recommendedPosts = {};
    const allPosts = () => ({...blogPosts, ...featuredPost, ...singlePost, ...recommendedPosts});

    $('#featured-blog-post-description').text((_, text) => {
        return handleEllipsis(text, 170);
    });
    window.addEventListener('popstate', (event) => setTimeout(() => {
        const {state} = event;
        if (!state || !state.postId) {
            setState({
                singlePost: '',
            });
        }

        if (state.postId) {
            setState({
                singlePost: state.postId,
            });
        }
    }, 0));


    const initialState = {
        perPage: 6,
        page: 1,
        topic: 'all',
        filter: '',
        singlePost: '',
    };

    let state = initialState;

    function getRecommendations() {
        return client.getEntries({
            content_type: 'blogPost',
            include: 10,
            order: '-fields.publishedAt',
            limit: 5,
            'fields.slug[nin]': state.singlePost,
        }).then(entries => {
            const $container = $('.blog-posts-recommended-container');
            $container.html('');
            const posts = handleEntries(entries);
            recommendedPosts = posts;
            for (let [_, {post, readTime}] of Object.entries(posts)) {
                $container.append(blogTile(post.fields, readTime, post.fields.slug));
            }
        })
        .catch(e => console.log(e));
    }

    function render(prevState) {
        $('#tags-picker .tag').removeClass('selected');
        $(`#tag-${state.topic}`).addClass('selected');

        if (prevState.singlePost !== state.singlePost) {
            if (state.singlePost) {
                const {htmlPost, post, readTime} = allPosts()[state.singlePost];
                $("meta[property^='og']").remove();
                $('head').append(
                    `<meta property="og:title" content="${post.fields.title}" />\n` +
                    `<meta property="og:description" content="${post.fields.content.content[0].content[0].value}" />\n` +
                    '<meta property="og:type" content="website" />\n' +
                    `<meta property="og:url" content="${window.location.href}" />\n` +
                    `<meta property="og:image" content="https:${post.fields.picture.fields.file.url}" />`
                );
                $('.single-post-container').html(htmlPost);
                $('.single-post-header').html(blogHeader(post, readTime));
                $('.single-post-footer .tags-container').html(blogTags(post.fields.tags));
                $('.blog-single').css('display', 'block');
                $('.blog-single-bottom-carousel').css('display', 'block');
                $('.blog-index').hide();
                $('.blog-post-share-container .twitter-button').attr('href', `https://twitter.com/intent/tweet?url=${window.location.href}`);
                $('.blog-post-share-container .linkedin-button').attr('href', `https://www.linkedin.com/sharing/share-offsite/?url=${window.location.href}`);
                getRecommendations();
            } else {
                $('.blog-single').hide();
                $('.blog-single-bottom-carousel').hide();
                $('.blog-index').show();
                $('.single-post-container').html('');
                $('.single-post-header').html('');
            }
            window.scrollTo(0, 0);
        }


        if (prevState.filter !== state.filter) {

        }
    }

    const setState = async (newState) => {
        const prevState = state;
        state = {...state, ...newState};
        render(prevState);
        await fetchPosts();
        //TODO: fix issue with bottom posts button not working
        await sleep(450);
        setupOnClickEvents();
    };

    function setupOnClickEvents() {
        $('.blog *, .blog-bottom *').off();


        $('#hub-subscribe label').click(function () {
            $(this).closest('form').submit();
        });

        $("#hub-subscribe").submit(function (e) {
            e.preventDefault();
            const $subscribeText = $(this).find('.subscribe-text');
            const $subscribeSpinner = $(this).find('.subscribe-spinner');
            $subscribeText.addClass('d-none');
            $subscribeSpinner.removeClass('d-none');
            const $data = $(this).closest('form').serialize();
            const $response = $(this).parent().find('.response');
            $response.removeClass('text-danger text-success');
            $response.html('');
            $.ajax({
                data: $data,
                method: 'POST',
                url: API_URL + 'subscribe_to_blog.php'
            }).done(function () {
                $response.addClass('text-success');
                $response.html('Thank you! You\'re subscribed!');
                $subscribeText.removeClass('d-none');
                $subscribeSpinner.addClass('d-none');
                setTimeout(function () {
                    $response.html('');
                }, 3000);

            }).fail(function (e) {
                $subscribeText.removeClass('d-none');
                $subscribeSpinner.addClass('d-none');
                $response.addClass('text-danger');
                $response.html('Please provide a valid email address.');
            });

        });


        $('.blog-tags').children().on('click', function () {
            setState({
                topic: $(this).attr('id').slice(4),
                page: 1,
            });
        });

        $('#search-blog').submit(function (e) {
            e.preventDefault();
            setState({
                filter: e.target.search.value,
                page: 1,
            });
        });

        function openBlogPost(id) {
            setState({
                singlePost: id,
            });
            history.pushState({postId: id}, null, `/blog/${id}`);
            // history.pushState({postId: id}, null, `/blog.html`);
        }

        $('.read-blog-button').on('click', function () {
            const id = $(this).parent().attr('id').slice(10);
            openBlogPost(id);
        });

        $('.read-blog-tile-button').on('click', function () {
            const id = $(this).attr('id').slice(10);
            openBlogPost(id);
        });

        $('.blog-single .back-button').on('click', function () {
            setState({
                singlePost: '',
            });
            history.pushState({postId: null}, null, `/blog`);
            // history.pushState({postId: null}, null, `/blog.html`);
        });
        const $container = $('.blog-posts-recommended');
        $('.blog-single-bottom-carousel .arrow-buttons .right-button').on('click', function () {
            $container.animate({scrollLeft: $container.scrollLeft() + $container.find('.blog-tile').outerWidth()});
        });
        $('.blog-single-bottom-carousel .arrow-buttons .left-button').on('click', function () {
            $container.animate({scrollLeft: $container.scrollLeft() - $container.find('.blog-tile').outerWidth()});
        });

        if (state.singlePost) {
            const {post} = allPosts()[state.singlePost];
            $('.blog-post-share-container .facebook-button').click(function () {
                FB.ui({
                    method: 'share',
                    display: 'popup',
                    href: window.location.href,
                    name: post.fields.title,
                    link: window.location.href,
                    picture: 'https:' + post.fields.picture.fields.file.url,
                    caption: post.fields.author,
                    description: post.fields.content.content[0].content[0].value,
                }, function (response) {
                });
            });
        }

        $('.blog-pagination .page-number').on('click', function () {
            const $offset = $('.blog-filters').offset();
            if ($offset) {
                $('html,body').animate({
                    scrollTop: $offset.top - 100
                }, 600);
            }
            setState({
                page: $(this).attr('id').slice(21),
            });
        });

        $('#book-demo').submit(e => {
            e.preventDefault();
            $('#chatModal').modal('show');
            $('#exampleInputEmail1').val(e.target.demo.value);
        });

        $('#book-demo label').click(function () {
            $(this).closest('form').submit();
        });

    }

    function handlePost(post) {
        const readTime = calculateReadTime(post.fields.content);
        const htmlPost = documentToHtmlString(post.fields.content, {
            renderNode: {
                [BLOCKS.PARAGRAPH]: (node, next) => `<p>${next(node.content).replace(/\n/g, '<br/>')}</p>`,
                [BLOCKS.EMBEDDED_ENTRY]: customRenderer,
            }
        });
        return {
            [post.fields.slug]: {htmlPost, post, readTime}
        };
    }

    function handleEntries(entries) {
        return entries.items.reduce((posts, post) => ({...posts, ...handlePost(post)}), {});
    }

    async function fetchPosts() {
        //depending on whether the featured post contains the selected 'topic', either fetch the first post or not
        const fetchFirst = (!Object.values(featuredPost)[0].post.fields.tags.some(tag => tag.sys.id === state.topic) && state.topic !== 'all')
            || state.filter;
        return client.getEntries({
            content_type: 'blogPost',
            include: 10,
            order: '-fields.publishedAt',
            skip: ((state.page - 1) * state.perPage) + (fetchFirst ? 0 : 1),
            limit: state.perPage,
            // 'fields.tags.sys.id': '7MAYsdRbuK6wPXT0OMGv0K'
            'fields.tags.sys.id': state.topic === initialState.topic ? '' : state.topic,
            query: state.filter,
        }).then(entries => {
            const $container = $('.blog-posts-container');
            $container.html('');
            const posts = handleEntries(entries);
            for (let [_, {post, readTime}] of Object.entries(posts)) {
                $container.append(blogTile(post.fields, readTime, post.fields.slug));
            }
            blogPosts = posts;
            $('.blog-pagination').html(blogPagination(Math.ceil((entries.total - (fetchFirst ? 0 : 1)) / entries.limit), (entries.skip - (fetchFirst ? 0 : 1)) / entries.limit + 1));
        })
        .catch(e => console.log(e));
    }

    // Fetch featured (most recent) post
    await client.getEntries({
        content_type: 'blogPost',
        include: 10,
        order: '-fields.publishedAt',
        limit: 1,
    }).then(entries => {
        featuredPost = handleEntries(entries);
    });

    const matchPath = path.match(/(\/blog\/)(.+)/);
    if (matchPath) {
        return client.getEntries({
            content_type: 'blogPost',
            include: 10,
            order: '-fields.publishedAt',
            limit: 5,
            'fields.slug[in]': matchPath[2],
        }).then(async entries => {
            const entry = entries.items[0];
            singlePost = handlePost(entry);
            await setState({
                singlePost: entry.fields.slug,
            });

        })
        .catch(e => console.log('ffa', e));
    } else {
        $('.blog-index').css('display', 'block');
    }


    await fetchPosts();
    setupOnClickEvents();
}

export default handleBlog();
