const loginScreen = (email, error) => `
        <div class="form-group email required user_email">
            <input name="email" type="email"
                   class="string email required form-control email-disabled"
                   id="user_email"
                   placeholder="Start with your email"
                   value=${email}
                   disabled
            />
        </div>
        <div class="form-group password required user_password">
            <input name="password" type="password"
                   id="user_password"
                   placeholder="Enter your password"
                   class="string password required form-control ${error ? 'error' : ''}"
            />
        </div>
`;

export default loginScreen;