const blogTags = tags => `
<div class="blog-tags">
    ${tags.map(tag => `
        <div class="tag selected"
             style="color: ${tag.fields.color}; ">
            <div class="tag-background"
                 style="background-color: ${tag.fields.color};"></div>
            <div class="tag-border"
                 style="border-color: ${tag.fields.color};"></div>
            ${tag.fields.name}
        </div>
    `).join('')}
</div>
`;

export default blogTags;
