const team = email => `
                    <div class="team-step-description">
                        You can always change this later
                    </div>
                    <div class="form-group">
                        <input required name="team_name" type="text"
                               class="string email required form-control"
                               placeholder="Type here"
                        />
                    </div>
                    <div class="checkbox privacy-terms-checkbox mx-auto">
                        <input required id="terms_and_privacy_accepted" name="terms_and_privacy_accepted"
                               type="checkbox"/>
                        <label for="terms_and_privacy_accepted">
                            By signing up, you agree to the collection, use and storage of personal information in
                            accordance with the terms of our
                            <a class="" href="https://heelix.com/terms.html" target="blank">Terms</a> and 
                            <a class="" href="https://heelix.com/privacy.html" target="blank">Privacy Policy</a>.
                        </label>
                    </div>
`;

export default team;