const missingInvite1 = email => `
        <div class="description-invite mx-auto">Looks like you're already on our system. If you're
            missing your invite enter your email
            below to request another one.
        </div>
        <div class="form-group email required user_email email-confirmation">
            <input name="email" type="email" class="string email required form-control"
                   id="user_email"
                   placeholder="Start with your email"
                   value="${email}"
                   required
            />
        </div>
`;

export default missingInvite1;