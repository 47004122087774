/* global grecaptcha, gapi */
import $ from 'jquery';
import {API_URL, CAPTCHA_KEY} from './constants';
import {saveAs} from 'file-saver';
import qs from 'qs';
import * as contentful from 'contentful';

window.grecaptcha.ready(function () {
    recaptchaId = grecaptcha.render(
        'captcha-container-3',
        {
            sitekey: CAPTCHA_KEY,
            callback: captchaCallback,
            size: 'invisible',
            badge: 'bottomright'
        }
    );
});

let recaptchaId;

$("#resource-page-form").submit(function (e) {
    e.preventDefault();
    grecaptcha.execute(recaptchaId);
});

async function captchaCallback(token) {
    grecaptcha.reset(recaptchaId);
    const self = $("#resource-page-form");
    const $submitField = self.find('#contact-submit-register');
    $submitField.prop('disabled', true);
    self.find('#contact-submit-webinar').prop('disabled', true);
    const data = self.closest('form').serializeArray();
    data.push({name: 'token', value: token});
    const $errorField = self.find('.error-message');
    $errorField.text('');

    $.ajax({
        data: data,
        method: 'POST',
        url: API_URL + 'resource.php',
        async: false,
    }).done(function (e) {
        self.find('select').val('0 - 50');
        self.find('#contact-submit-webinar').prop('disabled', false);
        console.log(JSON.parse(e));
        const data = JSON.parse(e);
        if (data.slug) {
            window.location.href = '/resource/success?file=' + data.slug;
            // window.location.href = '/resource-success.html?file=' + data.slug;
        }
        if (data.url) {
            fetch(data.url)
            .then(res => res.blob())
            .then(blob => {
                saveAs(blob, data.fileName);
            });

        }
    }).fail(function (e) {
        const error = JSON.parse(e.responseText).error || 'Something went wrong. Please try again later.';
        $errorField.text(error);
        $submitField.prop('disabled', false);
    });
}


$('#select-session-button').on('click', function () {
    const $offset = $('.webinar .top-section').offset();
    if ($offset) {
        $('html,body').animate({
            scrollTop: $offset.top
        }, 500);
    }
});

const path = window.location.pathname;
const matchPath = path.match(/(\/resource\/success)/);
// const matchPath = path.match(/(\/resource-success)/);
if (matchPath) {
    const query = qs.parse(window.location.search, {ignoreQueryPrefix: true});
    if (query.file) {
        const client = contentful.createClient({
            space: '0j1u7kevpjpm',
            accessToken: 'CNVBKRF3ljYsE4ukucvaHWbykJIRvy_RYizk2wervsU',
        });
        client.getEntries({
            content_type: 'resourcePage',
            include: 10,
            limit: 1,
            'fields.slug[in]': query.file,
            select: ['fields.attachment']

        }).then(async entries => {
            const entry = entries.items[0];
            const file = entry.fields.attachment.fields.file;
            fetch(file.url)
            .then(res => res.blob())
            .then(blob => {
                saveAs(blob, file.fileName);
                window.history.replaceState({}, null, window.location.pathname);
            });

        })
        .catch(e => console.log('ffa', e));
    }
} else {
}

