import $ from 'jquery';

$('.mobile-pricing-picker input').click(function () {
    const plan = $(this).val();
    $('.mobile-single-view > div').css('display', 'none');
    $(`#plan-view-${plan}`).css('display', 'block');
});

const planTypes = [
    {
        first: true,
        key: 25,
        value: '1-25 users',
        prices: {
            pro: {
                aud: 99,
                usd: 69,
            },
            plus: {
                aud: 129,
                usd: 90,
            },
        },
    },
    {
        key: 50,
        value: '25-50 users',
        prices: {
            pro: {
                aud: 199,
                usd: 138,
            },
            plus: {
                aud: 259,
                usd: 179,
            },
        },
    },
    {
        key: 100,
        value: '50-100 users',
        prices: {
            pro: {
                aud: 399,
                usd: 276,
            },
            plus: {
                aud: 519,
                usd: 359,
            },
        },
    },
    {
        key: 'custom',
        value: '100+ users',
        demo: true,
        prices: {
            pro: {
                image: '/img/media/ConnectPerformPuzzle.svg',
            },
            plus: {
                image: '/img/media/ConnectPerformProPuzzle.svg',
            },
        },
    },
    {
        key: 'perUser',
        value: 'Pay per user per month',
        prices: {
            pro: {
                aud: 6.99,
                usd: 4.75,
            },
            plus: {
                aud: 8.99,
                usd: 6.18,
            },
        },
    },
];

let currency = 'usd';
fetch('https://ip2c.org/s')
.then(response => response.text())
.then(json => {
    currency = json.split(';')[1] === 'AU' ? 'aud' : 'usd';
    changePrices(25, '.plan-section-');
    changePrices(25, '#plan-view-');
});

function changePrices(planSize, section) {
    const prices = planTypes.find(({ key }) => key == planSize).prices;
    const plans = ['pro', 'plus'];
    plans.forEach(plan => {
        if (prices[plan][currency]) {
            $(`${section}${plan} .price-image-container`).css('display', 'none');
            $(`${section}${plan} .price-section`).css('display', 'flex');
            $(`${section}${plan} .price-number`).text(prices[plan][currency]);
            $(`${section}${plan} .price-description`).text(`${currency.toUpperCase()} per month, billed annually`);
        } else if (prices[plan].image) {
            $(`${section}${plan} .price-section`).css('display', 'none');
            $(`${section}${plan} .price-image-container`).css('display', 'flex');
            $(`${section}${plan} .price-image-container img`).attr('src', prices[plan].image);
        }
    });
}
function changeButton(planSize) {
    const isDemo = Boolean(planTypes.find(({ key }) => key == planSize).demo);
    if (isDemo) {
        $('.plan-button').text('Get in touch');
        $('.plan-button').attr('href', '#chatModal');
    } else {
        $('.plan-button').text('Get started');
        $('.plan-button').attr('href', '#signInModal');
    }
}

$('.plan-size-select input').change(function () {
    const planSize = $(this).val();
    changePrices(planSize, '.plan-section-');
    changeButton(planSize, '.plan-section-');
});

$('.plan-size-select select').change(function () {
    const planSize = $(this).find('option:selected').val();
    changePrices(planSize, '#plan-view-');
    changeButton(planSize, '#plan-view-');
});

$('.desktop-pricing .feature-row').click(function () {
    const index = $(this).attr('collapseIndex');
    const toggle = $(`.feature-row[collapseIndex='${index}'] a`).attr('aria-expanded');
    $(`.collapseDesktopTab${index}`).collapse(toggle === 'false' ? 'show' : 'hide');
});
