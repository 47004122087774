import $ from 'jquery';

$(".contact-enterprise-form-button").click(function () {
    $("#hidden-form-section").attr('value', 'Enterpise Client');
});

$(".privacy-contact-form-button").click(function () {
    $("#hidden-form-section").attr('value', 'Privacy Policy');
});

$(".terms-contact-form-button").click(function () {
    $("#hidden-form-section").attr('value', 'Terms of Use');
});

$(".normal-contact-form-button").click(function () {
    $("#hidden-form-section").attr('value', 'Client Chat');
});

