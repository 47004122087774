import {APP_URL} from '../constants';
import steps from './steps';
import missingInvite2 from './views/missingInvite2';
import missingInvite1 from './views/missingInvite1';
import {setError} from './main';

export default async function handleInviteResend(email) {
    let data = new FormData();
    data.append('email', email);

    const response = await fetch(`${APP_URL}/invitation/resend`, {method: 'post', body: data});
    const json = await response.json();
    if (json.error) {
        setError(json.error);
    }
    if (json.status === 'OK') {
        return {
            step: steps.missing2,
            loading: '',
            title: 'Invite sent',
            buttonText: '',
            bottomText: '',
            content: missingInvite2(),
            email
        };
    }
}
