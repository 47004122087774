const register2 = email => `
                        <div class="verify-email">
                            <div>
                                Once you verify your email address, you and your team will be able to use Heelix
                                Connect and Perform Pro <strong>free</strong> for 21 days.
                            </div>
                            <div>
                                At the end of your 21 days you’ll be moved to our Free Product but we’ll also
                                give you the option to keep access to our great features by upgrading to Connect
                                and Perform or Connect and Perform Pro.
                            </div>
                            <div class="verify-didnt-receive">
                                Didn't receive an email? <a id="resend-verify-step">Click here</a>.
                            </div>
                        </div>
`;

export default register2;