import '../styles/index.scss';
import 'bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';
import './../Logo1.png';
import './../FeedScroll.gif';
import './../HeaderIllustrationMobile.png';
import './../HeaderIllustration.png';
import './../PlaceholderRectangle.png';
import './../BrainBackground.png';
import './../Brain.png';
import './../PortraitPlaceholder.png';
import './../Locked.svg';
import './../Intersection.svg';
import 'bootstrap/js/dist/tooltip';
import $ from 'jquery';
import './contactForm';
import { API_URL, COOKIE_DOMAIN } from './constants';
import './navbar';
import './navigation-dashboard';
import './check-in-viewport';
import './login/main';
import './carousel';
import './latest-blog-posts';
import './blog/blog';
import './corona';
import './sales';
import './login-v2';
import './webinar';
import './landing-page';
import './resource';
import './pricing';
import './register';

function getUrlVars() {
    var vars = [], hash;
    var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
    for (var i = 0; i < hashes.length; i++) {
        hash = hashes[i].split('=');
        vars.push(hash[0]);
        vars[hash[0]] = hash[1];
    }
    return vars;
}
function getPostData(postId) {
    $.ajax({
        url: API_URL + 'blog.php',
        accepts: 'application/json',
        data: { id: postId },
        context: $(this),
        success: function (data) {
            var posts = [];
            posts[postId] = data;
            window.postsContent = posts;
            setModalData(postId);
        },
        error: function (error) {
            console.log(error);
        }
    });
}

var mediaModalFbShare = $('#fb-share');
$(function () {

    mediaModalFbShare.click(function () {
        var map = $(this).attr('media-id');
        FB.ui({
            method: 'share',
            display: 'popup',
            href: baseURL + '/' + map
        }, function (response) { });
    });
    $('[data-toggle="tooltip"]').tooltip();
    var url = location.href;
    url.substring(url.lastIndexOf('/') + 1);

    if (url.search('blog_post') != -1) {
        var postId = /[^/]*$/.exec(window.location.href)[0];
        setSocialButtonsData(postId);

    }
    if (window.postsLoaded != true) {
        window.postsLoaded = true;
        $.ajax({
            url: API_URL + 'blog.php',
            accepts: 'application/json',
            context: $(this),
            success: function (data) {
                window.postsContent = data;

                renderPosts(data);
                //------Open media popup with ID from link
                if (getUrlVars().post) {
                    $(".media-caption-wrap[media-id='" + getUrlVars().post + "']").click();
                }
            },
            error: function (error) {
            }
        });


        $('.extra').hide();
    }


});


$("#subscribe-user-btn").click(function () {
    $('#subscribe-text').addClass('d-none');
    $('#subscribe-spinner').removeClass('d-none');
    var $data = $(this).closest('form').serialize();
    $('#response').removeClass('text-danger text-success');
    $.ajax({
        data: $data,
        method: 'POST',
        url: API_URL + 'subscribe_to_blog.php'
    }).done(function () {
        $('#response').addClass('text-success');
        $('#response').html('Thank you! You\'re subscribed!');
        $('#subscribe-text').removeClass('d-none');
        $('#subscribe-spinner').addClass('d-none');
        setTimeout(function () {
            $('#response').html('');
        }, 3000);

    }).fail(function (e) {
        $('#subscribe-text').removeClass('d-none');
        $('#subscribe-spinner').addClass('d-none');
        $('#response').addClass('text-danger');
        $('#response').html('Please provide a valid email address.');
    });

});


$('.collapse').on('shown.bs.collapse', function (e) {
    var $card = $(this).closest('.accordion-section');
    var $offset = $card.offset();
    if ($offset) {
        $('html,body').animate({
            scrollTop: $offset.top - 60
        }, 300);
    }
});

// (() => {
//     const cookieName = 'heelix_auth_token';
//     const cookieValue = 'qwerty';
//     let myDate = new Date();
//     myDate.setMonth(myDate.getMonth() + 12);
//     // document.cookie = cookieName + "=" + cookieValue + ";" + COOKIE_DOMAIN + "path=/;expires=" + myDate;
// })();


$(".sign-up-onclick").click(function () {
    ga('send', 'event', 'Buttons', 'Sign Up');
    // gtag_report_conversion('https://app.heelix.com/#/sign_up');
});

let renderPosts = function (data) {
    let $initialInvisible = '';
    let i = 0;

    $.each(data, function (index, element) {
        i++;

        if (i > 5) {
            $initialInvisible = 'extra';
        }
        let desc = '';
        if (element.custom_excerpt) {
            desc = element.custom_excerpt;
        }
        let $postHtml = element.html;
        if (element.is_video) {
            $postHtml = '<script src="https://fast.wistia.com/embed/medias/' + element.is_video + '.jsonp" async></script>' +
                '<script src="https://fast.wistia.com/assets/external/E-v1.js" async></script>' +
                '<span class="wistia_embed wistia_async_' + element.is_video + ' popover=true popoverContent=link" style="display:inline;position:relative">' +
                '                <a href="#">' +
                '            <div class="single-post-wrap ' + $initialInvisible + '">' +
                '                <div class="media-caption-wrap" data-id="' + element.is_video + '">' +
                '                    <h2>' + element.title + '</h2>' +
                '                    <h3>' + element.published_at + '</h3>' +
                '                    <p class="desktop">' + desc + '</p>' +
                '                    <img class="post-arrow" src="/img/media/arrow-dark-blue@2x.png">' +
                '                </div>' +
                '            </div>' +
                '                    </a></span>';
        } else {

            $postHtml = '<div class="single-post-wrap  ' + $initialInvisible + ' " >' +
                '<div class="media-caption-wrap"  media-id="' + element.slug + '">' +
                '<h2>' + element.title + '</h2>' +
                '<h3>' + element.published_at + '</h3>' +
                '<p class="desktop">' + desc + '</p>' +
                '<img class="post-arrow" src="/img/media/arrow-dark-blue@2x.png">' +
                '</div>' +
                '</div>';
        }
        $('#blog-post-list').append($postHtml);
    });


    $('.media-caption-wrap').click(function () {
        var id = $(this).attr('media-id');

        if (typeof id === 'undefined') {
            return;
        }
        location.href = location.protocol + '//' + location.host + '/blog_post/' + id;

    });



};

var buttonCaption = $('#view-all-btn');

buttonCaption.click(function () {
    if (buttonCaption.find('span').html() == 'View Less') {
        $('.extra').hide();
        buttonCaption.find('span').html('View All');

    } else {
        $('.extra').show();
        if ($(window).width() > 500) {
            $('.extra').css({ display: 'inline-flex' });
        } else {
            $('.extra').css({ display: 'block' });
        }

        buttonCaption.find('span').html('View Less');
    }
});

//var baseURL = location.protocol + '//' + location.host + location.pathname;
var baseURL = location.protocol + '//' + location.host + '/blog_post';

function setModalData(postId) {
    var mediaModalTitle = $('#post-modal h2');
    var mediaModalImg = $('#post-modal .media-img');
    var mediaModalDate = $('#post-modal h3.date');
    var mediaModalAuthorName = $('#post-modal h3.author-name');
    var mediaModalCaption = $('#post-modal p');
    var mediaModalAuthorDesc = $('#post-modal h5');
    var mediaModalAuthorImg = $('#post-modal .author-img-wrap');
    var mediaModalEmailShare = $('#post-modal #email-share');
    var topImage = $('#top-image');
    var topImageContainer = $('.header-illustration.blog-post');
    //var postTitle = $('#post-title');
    topImage.attr("src", postsContent[postId]['feature_image']);
    topImageContainer.css({ 'background-image': 'url(' + postsContent[postId]['feature_image'] + ')' });
    //postTitle.text(postsContent[postId]['title']);
    var mediaModalLinkedInShare = $('#post-modal #li-share');
    var mediaModalTwitterShare = $('#post-modal #tw-share');


    mediaModalTitle.html(postsContent[postId]['title']);
    if (postsContent[postId]['feature_image'] && postsContent[postId]['feature_image'] !== 'null') {
        mediaModalImg.html('<img src="' + postsContent[postId]['feature_image'] + '"/>');
    }
    mediaModalAuthorName.html('By ' + postsContent[postId]['author_name']);
    mediaModalDate.html(postsContent[postId]['published_at']);
    if (postsContent[postId]['html'] && postsContent[postId]['html'] !== null) {
        mediaModalCaption.html(postsContent[postId]['html']);
    }
    mediaModalAuthorDesc.html(postsContent[postId]['author_description']);
    mediaModalAuthorImg.html(postsContent[postId]['author_img']);


    var mailBody = 'Hi%2C%0A%0AI found this post from heelix.com that you might be interested in:%0A%0A' +
        baseURL + '/' + postsContent[postId]['slug'];
    mediaModalEmailShare.attr('href', 'mailto:?body=' + mailBody + '&subject=Heelix.com - ' + postsContent[postId]['title']);
    mediaModalTwitterShare.attr('href', 'https://twitter.com/intent/tweet?&ref_src=twsrc%5Etfw&text=' + baseURL + '?post=' + postsContent[postId]['slug']);
    mediaModalLinkedInShare.attr('href', 'https://www.linkedin.com/sharing/share-offsite/?url=' + encodeURIComponent(baseURL + '?post=' + postsContent[postId]['slug']));
    mediaModalFbShare.attr('media-id', postsContent[postId]['slug']);

}

var setSocialButtonsData = function (postId) {
    var mediaModalEmailShare = $('#post-modal #email-share');
    var mediaModalLinkedInShare = $('#post-modal #li-share');
    var mediaModalTwitterShare = $('#post-modal #tw-share');
    var mailBody = 'Hi%2C%0A%0AI found this post from heelix.com that you might be interested in:%0A%0A' +
        baseURL + '/' + postId;
    mediaModalEmailShare.attr('href', 'mailto:?body=' + mailBody + '&subject=Check out the Heelix.com blog');
    mediaModalTwitterShare.attr('href', 'https://twitter.com/intent/tweet?&ref_src=twsrc%5Etfw&text=' + baseURL + '/' + postId);
    mediaModalLinkedInShare.attr('href', 'https://www.linkedin.com/sharing/share-offsite/?url=' + encodeURIComponent(baseURL + '/' + postId));
    mediaModalFbShare.attr('media-id', postId);
};

var setSocialVideoData = function (video) {
    var videoId = video.hashedId();
    var mailBody = 'Hi%2C%0A%0AI found this post from heelix.com that you might be interested in:%0A%0A' +
        baseURL + '?wvideo=' + videoId;
    $('.social-buttons .email-share').attr('href', 'mailto:?body=' + mailBody + '&subject=Heelix.com - ' + video.name());
    $('.social-buttons .twitter-share').attr('href', 'https://twitter.com/intent/tweet?&ref_src=twsrc%5Etfw&text=' + baseURL + '?wvideo=' + videoId);
    $('.social-buttons .linkedin-share').attr('href', 'https://www.linkedin.com/sharing/share-offsite/?url=' + encodeURIComponent(baseURL + '?wvideo=' + videoId));
    $('.social-buttons .fb-share').attr('media-id', videoId);
};
var adjustSocialButtonsPosition = function (video) {


    var positionTop = ($(window).height() - video.height()) / 2 + video.height() + $(window).scrollTop() + 10;
    var positionRight = $(window).width() / 2 - (video.width() / 2);

    $('.social-buttons').css({ 'top': positionTop + 'px', 'right': positionRight, 'z-index': '100000' });
    $('.social-buttons').css({ 'z-index': '100000' });
};
window._wq = window._wq || [];
_wq.push({
    id: "_all", onReady: function (video) {
        //hide buttons when popover hides
        video.bind("popoverhide", function () {
            $('.social-buttons').hide();
        });
        // adjust the position and links of social buttons when play starts and unhide them
        video.bind('play', function () {
            adjustSocialButtonsPosition(video);
            $('.social-buttons').show();
            setSocialVideoData(video);
        });
        video.bind("end", function () {
            // console.log("Lenny was here.");
        });
        video.bind("heightchange", function () {
            // console.log("The height changed to " + video.height());
            adjustSocialButtonsPosition(video);
        });

    }
});

