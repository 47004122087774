import { APP_URL, CLIENT_ID, CLIENT_SECRET, COOKIE_DOMAIN, HEELIX_URL } from '../constants';
import steps from './steps';
import register2 from './views/register2';
import loginScreen from './views/loginScreen';
import forgotPassword from './views/forgotPassword';
import redirectToHeelix from './redirectToHeelix';

export default async function handleGoogleLogin(email, token) {
    let data = new FormData();
    data.append('username', email);
    data.append('token', token);

    const response = await fetch(`${APP_URL}/login_google`, { method: 'post', body: data });
    const json = await response.json();
    if (!(response.status === 200 && response.ok === true)) {
        let title, error;
        if (json.message === "The user credentials were incorrect.") {
            error = 'Oops, that password doesn\'t match';
        } else if (json.message) {
            error = json.message;
        } else if (json.error) {
            error = json.error;
        } else {
            error = 'Oops, that password doesn\'t match';
        }
        title = `<span class="error-color">${error}</span>`;

        return {
            step: steps.login,
            loading: '',
            title: title,
            buttonText: 'Login',
            bottomText: forgotPassword,
            content: loginScreen(email, true),
            email,
            error,
        };
    } else {
        const cookieName = 'heelix_auth_token';
        const cookieValue = json.token;
        let myDate = new Date(new Date().getTime() + 3600 * 1000);
        document.cookie = cookieName + "=" + cookieValue + ";" + COOKIE_DOMAIN + "path=/;expires=" + myDate;
        redirectToHeelix();

        return { loading: 'Verifying credentials' };
    }
}
