import $ from 'jquery';

$("#landing-learn-more").click(function () {
    const $offset = $('.landing-page .features-list').offset();
    if ($offset) {
        $('html,body').animate({
            scrollTop: $offset.top - 35
        }, 500);
    }
});

$(".landing-page .register-now .trial-button a").click(function (e) {
    e.preventDefault();
    const $offset = $('.landing-page .top-section').offset();
    if ($offset) {
        $('html,body').animate({
            scrollTop: $offset.top - 35
        }, 500);
    }
});
