import GoogleButtonLogo from '../../../GoogleButtonLogo.png';
import {CAPTCHA_KEY} from '../../constants';

const body = (callback) => `
    <div class="google-button text-center mx-auto">
        <button id="google-signin-button" type="button" class="btn btn-light text-center mx-auto">
            <img src="${GoogleButtonLogo}" alt="">
            <span>Use Google account</span>
        </button>
    </div>
    <div id="appleid-signin" class="apple-button text-center mx-auto" data-color="white" data-border="true" data-type="sign in">
    </div>
    <div class="horizontal-line mx-auto">
        <span>Or</span>
    </div>
    <div class="form-group">
        <input name="email" required type="email" class="form-control" id="email"
               aria-describedby="email" placeholder="Start with your email">
    </div>
`;
export default body;