import {APP_URL, HEELIX_URL} from '../constants';
import loginScreen from './views/loginScreen';
import steps from './steps';
import missingInvite1 from './views/missingInvite1';
import register1 from './views/register1';
import forgotPassword from './views/forgotPassword';

export default async function handleVerifyEmail(email, token) {
    const response = await fetch(
        `${APP_URL}/lookup?email=${encodeURIComponent(email)}&captcha_token=${encodeURIComponent(token)}`,
        {method: 'get'}
    );
    const json = await response.json();

    const {user} = json;

    if (email && (user === 'exists' || user === 'both' || user === 'demo')) {
        return {
            step: steps.login,
            loading: '',
            title: 'Welcome back!',
            buttonText: 'Login',
            bottomText: forgotPassword,
            content: loginScreen(email),
            email,
            user,
        };
    }
    if (email && user === 'invited') {
        return {
            step: steps.missing1,
            loading: '',
            title: 'Missing your invitation?',
            buttonText: 'Request invite',
            bottomText: '',
            content: missingInvite1(email),
            email
        };
    }
    if (email && user === 'doesn\'t exist') {
        return {
            step: steps.register1,
            loading: '',
            title: 'Looks like you\'re new!',
            buttonText: 'Continue with account creation',
            bottomText: '',
            content: register1(email),
            email
        };
    }
    //TODO
    return '123';
};

