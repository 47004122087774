import $ from 'jquery';



$(document).ready(function () {


    $(".navigation .col").click(function () {
        selectNavigationElement($(this));
    });

    function lazyLoad() {
        $('.lazy-img').each(function () {
            $(this).attr('src', $(this).data('src'));
            $(this).removeClass('lazy-img');
        });
    }
    lazyLoad();
});


export function selectNavigationElement(element) {
    const img = element.data("img");
    const description = element.data("description");
    const parent = element.closest('.navigation-dashboard');

    parent.find('img').addClass("d-none");

    $(".navigation .col").removeClass("active");
    element.addClass("active");
    element.find('img').removeClass("d-none");
    $("#img-to-change").addClass("d-none").attr("src", img).removeClass("d-none");
    $("#description-dashboard").addClass("d-none").text(description).removeClass("d-none");
}