export default function customRenderer({ data, content }) {
    const md = require('markdown-it')();
    const fields = data.target.fields;
    switch (data.target.sys.contentType.sys.id) {
        case 'blogPicture':
            return `
<div class="blog-picture-description-container">
    <div class="picture">
    <img src="${fields.picture.fields.file.url}" alt="">
    </div>
    ${fields.description ? `
        <div class="description">
                ${md.renderInline(fields.description)}
        </div>` : ''}
</div>`;
        case 'blogQuote':
            return `
<div class="blog-quote-description">
    <div class="content">
        ${fields.content}
    </div>
    <div class="author">
        ${fields.author}
    </div>
</div>`;
        case 'blogList':
            return `
<div class="blog-picture-list">
            ${fields.items.map(({ fields }) => `
    <div class="list-item">
        <div class="item-container">
            <div class="picture">
                <img src="${fields.picture.fields.file.url}" alt="">
            </div>
            <div class="content">
                <div class="title">
                    ${fields.title}
                </div>
                <div class="description">
                    ${fields.description}
                </div>
            </div>
        </div>
    </div>
            `).join('')}
</div>
            `;
        case 'blogEmbedYoutube':
            switch (fields.type) {
                case 'wistia':
                    const regex = /\w+\.(?:wistia\.com|wi\.st)\/(?:(?:\w|-)*\/)(?:medias)\/(\w{10})/;
                    const regexMatch = fields.url.match(regex);
                    if (!regexMatch || regexMatch.length < 2) return '';
                    const id = regexMatch[1];
                    return `
    <div class="wistia_responsive_padding">
      <div class="wistia_responsive_wrapper" >
        <iframe src="https://fast.wistia.net/embed/iframe/${id}?videoFoam=true"
                allowTransparency="true"
                frameBorder="0"
                scrolling="no"
                class="wistia_embed"
                name="wistia_embed"
                allowFullScreen
                mozallowfullscreen
                webkitallowfullscreen
                oallowfullscreen
                msallowfullscreen
                width="100%"
                height="100%"/>
      </div>
    </div>
    <script src="https://fast.wistia.net/assets/external/E-v1.js" async></script>
                    `;
                case 'youtube':
                default:
                    return `
            <iframe
                width="560" height="315" src="${fields.url}" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen
            ></iframe>
            `;
            }
        default:
            return null;
    }
}
