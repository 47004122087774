import blogTags from './blogTags';

const blogHeader = (post, readTime) => `
<div class="title">
    ${post.fields.title}
</div>
<div class="top-bar">
    <div class="details">
        ${post.fields.author}
        <span>|</span>
${(() => {
    const date = new Date(post.fields.publishedAt);
    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"];
    return `${monthNames[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
})()}
    </div>
    <div class="read-time">
    ${readTime} min read
    </div>
    ${blogTags(post.fields.tags)}
</div>
<div class="title-picture">
    <img src="${post.fields.picture.fields.file.url}" alt="">
</div>
`;
export default blogHeader;