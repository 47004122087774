import $ from 'jquery';

let isIntroducingDescriptionClicked = false;

function selectIntroducingDescription(i, $this) {
    $(`#introducingContainerDescription > div`).removeClass('selected');
    $this.addClass('selected');

    $(`#introducingContainerImage > img`).attr('hidden', true);
    $(`#introducingImage${i}`).removeAttr('hidden');
}

const descriptionsCount = 4;

for (let i = 0; i < 4; i++) {
    $(`#introducingDescription${i}`).click(function () {
        isIntroducingDescriptionClicked = true;
        selectIntroducingDescription(i, $(this));
    });
}

function rotateIntroducingDescriptions() {
    let previousIndex = 0;
    const interval = setInterval(function () {
        if (isIntroducingDescriptionClicked) {
            clearInterval(interval);
            return;
        }
        previousIndex = (previousIndex + 1) % descriptionsCount;
        selectIntroducingDescription(previousIndex, $(`#introducingDescription${previousIndex}`));
    }, 3000);
}

rotateIntroducingDescriptions();

for (let i = 0; i <= 12; i++) {
    $(`#customerLogo${i}`).click(function () {
        $(`#customerLogos > div`).removeClass('selected');
        $(this).addClass('selected');

        $(`#customerDetails > div`).attr('hidden', true);
        $(`#customerDetail${i}`).removeAttr('hidden');
    });
}

$('#features-button').click(() => {
    const $offset = $('#features').offset();
    if ($offset) {
        $('html,body').animate({
            scrollTop: $offset.top - 120
        }, 400);
    }
});

$('.title-option-2').html('Connect & Perform <span style="color: #FF5C3E;">Pro</span>');

$('.shadow-container > div').scroll(function () {
    const $parent = $(this).parent();
    const $members = $(this).find('.items-container');
    const offset = $members.offset();
    if (offset.left < -15) {
        $parent.find('.white-shadow-left').width('113px');
    } else {
        $parent.find('.white-shadow-left').width(0);
    }
    const $furthestRight = $(this).find('.items-container > div:last-child');
    const offsetRight = $(window).width() - ($furthestRight.offset().left + $furthestRight.outerWidth());
    if (offsetRight < -15) {
        $parent.find('.white-shadow-right').width('113px');
    } else {
        $parent.find('.white-shadow-right').width(0);
    }
});
$(document).ready(() => {
    window.scrollBy(0, 1);
    addEventListener('load', () => {
        const $shadow = setPricingTableShadowHeight();
        setAllFeaturesButtonAnimationHandlers($shadow);
    });
});

function setPricingTableShadowHeight() {
    const $table = $('#desktop-pricing-table');
    const height = $table.height() - $table.find('.recommended').height();
    const $shadow = $table.find('.middle-shadow');
    $shadow.height(height);
    return $shadow;
}

function setAllFeaturesButtonAnimationHandlers($shadow) {

    $('.all-features-button').click(function () {
        const $parent = $(this).parent();
        const $checkmarkList = $parent.find('.checkmark-list');
        const index = $parent.index();
        let height = 0, minHeight = 0;
        $checkmarkList.children().each(function () {
            height += $(this).outerHeight(true);
            // if ($(this).index() === 3) {
            //     minHeight = height;
            // }
        });
        minHeight = 180;
        if (!$checkmarkList.hasClass('toggled')) {
            $checkmarkList.css('max-height', `${height}px`);
            if (index === 1) {
                $shadow.height($shadow.height() + height - minHeight);
            }
        } else {
            $checkmarkList.css('max-height', `${minHeight}px`);
            if (index === 1) {
                $shadow.height($shadow.height() - height + minHeight);
            }
        }
        $checkmarkList.toggleClass('toggled');
        $(this).toggleClass('toggled');
        $(this).parent().find('.white-bottom-gradient').toggleClass('toggled');
    });
}



