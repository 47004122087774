const register1 = email => `
                        <div class="form-group email required user_email email-confirmation">
                            <input name="email" type="email" class="string email required form-control"
                                   id="user_email"
                                   placeholder="Start with your email"
                                   value="${email}"
                                   required
                            />
                        </div>
`;

export default register1;