import {APP_URL} from '../constants';
import steps from './steps';
import missingInvite2 from './views/missingInvite2';
import register2 from './views/register2';
import {setError} from './main';

export default async function handleRegister(email, token) {
    let data = new FormData();
    data.append('email', email);
    data.append('captcha_token', token);

    const response = await fetch(`${APP_URL}/confirmation/send`, {method: 'post', body: data});
    const json = await response.json();
    if (json.error) {
        return setError(json.message);
    }
    return {
        step: steps.register2,
        loading: '',
        title: 'Please verify your email',
        buttonText: '',
        bottomText: '',
        content: register2(),
        email
    };
}
