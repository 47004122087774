/* global grecaptcha, gapi */
import $ from 'jquery';
import 'slick-carousel';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { API_URL, CAPTCHA_KEY } from './constants';

$('#faq-section .tile').click(function () {
    $(this).toggleClass('selected');
});
$(document).ready(function () {
    const $slider = $('.clients');
    const settings = {
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
    };

    function resizeListener() {
        if ($(window).width() >= 992) {
            if ($slider.hasClass('slick-initialized')) {
                $slider.slick('unslick');
            }
        } else {
            if (!$slider.hasClass('slick-initialized')) {
                $slider.slick(settings);
            }
        }
    }

    $(window).bind('resize', resizeListener);
    resizeListener();

    let recaptchaId;
    window.grecaptcha.ready(function () {
        recaptchaId = grecaptcha.render(
            'captcha-container-4',
            {
                sitekey: CAPTCHA_KEY,
                callback: captchaCallback,
                size: 'invisible',
                badge: 'bottomright'
            }
        );
    });

    async function captchaCallback(token) {
        grecaptcha.reset(recaptchaId);
        const self = $("#register-page-contact-form");
        const $submitField = self.find('#contact-submit-register');
        $submitField.prop('disabled', true);
        self.find('#contact-submit-register').prop('disabled', true);
        const $data = self.closest('form').serializeArray();
        $data.push({name: 'token', value: token});
        const $errorField = self.find('.error-message');
        $errorField.text('');

        $.ajax({
            data: $data,
            method: 'POST',
            url: API_URL + 'contact.php'
        }).done(function () {
            self.find('input, textarea').val('');
            self.find('select').val('0 - 50');
            self.find('#contact-submit-register').prop('disabled', false);
            window.location.href = '/demo/success';
        }).fail(function (e) {
            const error = JSON.parse(e.responseText).error || 'Something went wrong. Please try again later.';
            $errorField.text(error);
            $submitField.prop('disabled', false);
        });
    }

    $("#register-page-contact-form").submit(function (e) {
        e.preventDefault();
        grecaptcha.execute(recaptchaId);
    });
});
