/* global grecaptcha, gapi */
import {APP_URL, CAPTCHA_KEY, CLIENT_ID, CLIENT_SECRET, COOKIE_DOMAIN, GOOGLE_KEY} from './constants';
import redirectToHeelix from './login/redirectToHeelix';
import $ from 'jquery';
import handleGoogleLogin from './login/handleGoogleLogin';

const state = {
    email: '',
    password: '',
};

let recaptchaId;

/**
 * @param token
 * @returns {Promise<{ok: boolean}|{ok: boolean, error: string, message: string}>}
 */
async function handleLogin(token) {
    let data = new FormData();
    data.append('grant_type', 'password');
    data.append('client_id', CLIENT_ID);
    data.append('client_secret', CLIENT_SECRET);
    data.append('username', state.email);
    data.append('password', state.password);
    data.append('scope', '');
    data.append('captcha_token', token);

    const response = await fetch(`${APP_URL}/oauth/token`, {method: 'post', body: data});
    const json = await response.json();
    if (!(response.status === 200 && response.ok === true)) {
        return {
            ok: false,
            error: json.error,
            message: json.message,
        };
    }

    const cookieName = 'heelix_auth_token';
    const cookieValue = json.access_token;
    let myDate = new Date(new Date().getTime() + 3600 * 1000);
    document.cookie = cookieName + "=" + cookieValue + ";" + COOKIE_DOMAIN + "path=/;expires=" + myDate;
    redirectToHeelix();

    return {
        ok: true,
    };
}

function setError(error) {
    const $container = $('#error-container');
    if (error) {
        $container.show().text(error);
    } else {
        $container.hide().text('');
    }
}

function setLoading(isLoading) {
    const $container = $('#login-v2-form');
    if (isLoading) {
        $container.find('input').prop('disabled', true);
        $container.find('.login-button-text').addClass('d-none');
        $container.find('.login-spinner').removeClass('d-none');
    } else {
        $container.find('input').prop('disabled', false);
        $container.find('.login-button-text').removeClass('d-none');
        $container.find('.login-spinner').addClass('d-none');
    }
}

async function onSuccess(user) {
    const token = user.getAuthResponse(true).access_token;
    const response = await fetch(`${APP_URL}/lookup_google?token=${token}`);
    const json = await response.json();
    if (json.user === 'exists') {
        const object = await handleGoogleLogin(user.getBasicProfile().getEmail(), token);
        if (object.error) {
            setError(object.error);
        }
    } else if (json.user === "doesn't exist") {
        window.location.href = '/register';
    } else if (json.user === 'not company email') {
        setError(json.message);
    }
}

function attachSignin(element) {
    auth2.attachClickHandler(element, {},
        onSuccess, function (error) {
            console.log(JSON.stringify(error, undefined, 2));
        });
}

let auth2;
const loadGapi = () => gapi.load('auth2', function () {
    auth2 = gapi.auth2.init({
        client_id: GOOGLE_KEY,
        cookiepolicy: 'single_host_origin',
        prompt: 'select_account',
    });
    const btn = document.getElementById('sign-in-button-google-2');
    btn && attachSignin(btn);
});

async function captchaCallback(token) {
    grecaptcha.reset(recaptchaId);
    const loginState = await handleLogin(token);
    setLoading(false);
    if (!loginState.ok) {
        setError(loginState.message);
    }
}

$('#login-v2-form').submit(async e => {
    e.preventDefault();
    setLoading(true);
    setError();
    state.email = e.target.email.value;
    state.password = e.target.password.value;
    grecaptcha.execute(recaptchaId);
});

window.grecaptcha.ready(function () {
    recaptchaId = grecaptcha.render(
        'captcha-container-2',
        {
            sitekey: CAPTCHA_KEY,
            callback: captchaCallback,
            size: 'invisible',
            badge: 'bottomright'
        }
    );
    // captchaRendered = true;
    // if (signInFormEmail) {
    //     setState({loading: 'Verifying email', email: signInFormEmail});
    //     grecaptcha.execute();
    // }
});

$(document).ready(() => {
    $('.sign-in-button.apple').click(function () {
        window.AppleID.auth.signIn({
            clientId: 'com.winning.heelix',
            scope: 'name email',
            // redirectURI: `http://dev.coderspeak.com:9001/apple_redirect`, //for local debugging
            redirectURI: `${APP_URL}/apple_redirect`,
        });
    });
    loadGapi();

});

