import $ from 'jquery';
import clamp from 'clamp-js';

(async () => {
    for (let i = 0; i < 3; i++) {
        const node = $('.post-tiles-container > .post-tiles > .post-card > .content-container > .content')[i];
        if (node) {
            clamp(node, {clamp: 4, useNativeClamp: true});
        }
    }
})();
