import {handleEllipsis} from '../blog';
import {documentToPlainTextString} from '@contentful/rich-text-plain-text-renderer';

const blogTile = (fields, readTime, id) => `
<div id="blog-post-${id}" class="blog-tile read-blog-tile-button">
    <div class="picture">
        <img src="${fields.picture.fields.file.url}" alt="">
    </div>
    <div class="blog-tags">
        ${fields.tags.map(tag => `
            <div class="tag selected"
                 style="color: ${tag.fields.color}; ">
                <div class="tag-background"
                     style="background-color: ${tag.fields.color};"></div>
                <div class="tag-border"
                     style="border-color: ${tag.fields.color};"></div>
                ${tag.fields.name}
            </div>
        `).join('')}
    </div>
    <div class="title">
        ${fields.title}
    </div>
    <div class="description">
        ${handleEllipsis(documentToPlainTextString(fields.content), 130)}
    </div>
    <div class="read">
        ${readTime} min read
        <img src="/img/media/blog-read-arrow.svg" alt="">
    </div>
</div>
    `;
export default blogTile;
