import $ from 'jquery';
import Dropzone from 'dropzone';
import { API_URL } from './constants';

const previewTemplate = `
<div class="dz-preview dz-file-preview">
  <div class="dz-details">
    <div class="dz-filename"><span data-dz-name></span></div>
    <div class="dz-size" data-dz-size></div>
  </div>
  <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
  <div class="dz-error-message"><span data-dz-errormessage></span></div>
</div>
`;

Dropzone.options.salesDropzone = {
    url: API_URL + 'join-us.php',
    maxFilesize: 5,
    maxFiles: 1,
    autoProcessQueue: false,
    acceptedFiles: 'text/plain,application/pdf,application/msword,application/vnd.oasis.opendocument.text,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    addRemoveLinks: true,
    previewTemplate,
    init: function () {
        const $self = $("#sales-page-contact-form");
        const myDropzone = this;
        const $submitField = $self.find('#contact-submit-sales');
        const $errorField = $self.find('.error-message');

        $self.submit(function (e) {
            e.preventDefault();
            if (!myDropzone.files || !myDropzone.files.length) {
                return $self.find('.error-message').text('File attachment is required.');
            }
            $self.find('#contact-submit-sales').prop('disabled', true);
            $self.find('.error-message').text('');
            myDropzone.processQueue();
            return;
            const self = $(this);
            e.preventDefault();
            const $submitField = self.find('#contact-submit');
            $submitField.prop('disabled', true);
            const $data = self.closest('form').serialize();
            return console.log($data);
            const $errorField = self.find('.error-message');
            $errorField.text('');

            $.ajax({
                data: $data,
                method: 'POST',
                url: API_URL + 'contact.php'
            }).done(function () {
                self.find('input, textarea').val('');
                self.find('select').val('0 - 50');
                self.find('#contact-submit').prop('disabled', false);
                $('#chatModal').modal('toggle');
            }).fail(function (e) {
                const error = JSON.parse(e.responseText).error || 'Something went wrong. Please try again later.';
                $errorField.text(error);
                $submitField.prop('disabled', false);
            });
        });

        myDropzone.on('addedfile', function (file, response) {
            $errorField.text('');
            $('.dz-message').css('display', 'none');
        });

        myDropzone.on('removedfile', function (file, response) {
            $('.dz-message').css('display', 'flex');
        });

        myDropzone.on('sending', function (file, xhr, formData) {
            const $data = $("#sales-page-contact-form").closest('form');
            $(":input[name]", $data).each(function () {
                formData.append(this.name, $(':input[name=' + this.name + ']', $("form")).val());
            });
            formData.append('fileName', file.name);
            formData.append('fileType', file.type);
        });
        myDropzone.on('error', function (file, e, xml) {
            if (!xml && file.status === 'error') {
                myDropzone.removeAllFiles();
            }
            const error = e.responseText && JSON.parse(e.responseText).error || 'Something went wrong. Please try again later.';
            $errorField.text(xml ? error : e);
        });

        myDropzone.on('success', function (file, response) {
            window.location.href = '/join-us/success';
        });
        myDropzone.on('complete', function (file, response) {
            $submitField.prop('disabled', false);
        });
    }
};

